import { extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import BannersAPI from '../services/BannersAPI';
import UploadAPI from '../services/UploadAPI';
import BranchModel from '../models/BranchModel';
import BannerModel from '~/models/BannerModel';

class BannersStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();

    extendObservable(this, {
      bannersList: [],
      bannerListSelect: undefined,
      banner: undefined,
      bannerFile: {},
      bannerEdit: {},
      branchUuid: undefined,
      totalPages: 0,
      size: 15,
      page: 0,
      sort: 'name_tag',
      filters: {},
      loading: false,
      bannerTags: [],
    });
  }

  reset() {
    this.bannersList = [];
    this.bannerListSelect = undefined;
    this.banner = undefined;
    this.bannerFile = {};
    this.bannerEdit = {};
    this.totalPages = 0;
    this.size = 15;
    this.page = 0;
    this.sort = 'name_tag';
    this.loading = false;
  }

  initializeBanner() {
    this.banner = new BannerModel();
    this.bannerFile = {};
  }

  async getList(
    size = 15,
    page = this.page,
    sort = this.sort !== 'nameTag' ? this.sort : 'name_tag',
    filters = this.filters,
  ) {
    this.loading = true;
    const params = {
      page: page,
      size: size,
      sort: sort,
      tag: filters?.tag?.value,
      active: filters?.active?.value,
      nameTag: filters?.nameTag,
      products: filters?.products,
    };
    const response = await BannersAPI.getList(params);
    this.loading = false;

    if (response.error) {
      this.bannersList = [];
      return;
    }

    this.bannersList = response.content.map(
      (banner) => new BannerModel(banner)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.size = response.size;
  }

  async setPage(numPage, size = 15, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = 'name_tag';

    sort ? (this.sort = sort) : (this.sort = 'name_tag');

    await this.getList();
    return true;
  }

  async setSort(sort) {
    this.sort = sort
    await this.getList(this.size, this.page, this.sort);
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  async saveBranch(uuid) {
    this.branchUuid = uuid;
  }

  removeTags(response, tags) {
    return response.filter((el) => !tags.includes(el.tag));
  }

  async getTags() {
    const response = await BannersAPI.getTags();
    if (!response.error) {
      this.bannerTags = this.removeTags(response.bannerTypes, 'PRODUCT');
      this.bannerTags = this.bannerTags.map((tag) => {
        return { label: tag.description, value: tag.tag };
      });
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return [];
  }

  async getBannerListSelect() {
    const response = await BannersAPI.getList({
      size: 300,
      page: 0,
    });

    if (response.error) {
      this.bannerListSelect = [];
      return;
    }

    this.bannerListSelect = response.content.map((banner) => ({
      value: banner.uuid,
      label: banner.nameTag,
    }));
  }

  async uploadFiles(files) {
    const promisses = files.map(async (item) => {
      const response = await UploadAPI.saveFile([item.file], 'banner');

      if (response.error) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
        return "Erro ao salvar imagem";
      } else {
        return {
          '@class': 'br.com.stoom.avenida.model.AvenidaBannerFile',
          file: {
            uuid: response.data.uuid,
          },
          name: item.name,
          ordering: item.ordering,
          description: item.description,
          link: item.link,
          link2: item.link2,
          name2: item.name2,
          price2: item.price2,
          price: item.price,
          categoryBranch: item.categoryBranch,
        };
      }
    });

    const responses = await Promise.all(promisses);

    if (responses && responses?.filter((el) => el?.error).length > 0) {
      await this.roollbackFiles(responses?.filter((el) => !el?.error));
      throw new Error(
        responses
          .filter((el) => el?.error)
          .map((el) => el?.error)
          .join('; ')
      );
    }

    return responses;
  }

  async roollbackFiles(files) {
    try {
      const promisses = files.map((file) =>
        UploadAPI.removeFile(file.file.uuid)
      );
      await Promise.all(promisses);
    } catch (error) {
      console.log('roollbackFiles error', error);
    }
  }

  async save() {
    this.loading = true;

    const filesResponses = await this.uploadFiles(this.banner.files);
    const data = JSON.stringify({
      ...this.banner,
      '@class': 'br.com.stoom.avenida.model.AvenidaBanner',
      files: filesResponses?.filter((file) => !file?.error),
    });

    const response = await BannersAPI.save(data);
    this.loading = false;

    if (response?.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Salvo com sucesso!');
    }

    return response;
  }

  async update() {
    this.loading = true;

    const merchant = this.rootStore.usersStore.userMerchant;

    const newFiles = this.banner.files.filter(
      (item) => item.file.uuid === undefined
    );
    const files = this.banner.files.filter(
      (item) => item.file.uuid !== undefined
    );
    const filesResponses = await this.uploadFiles(newFiles);
    if (filesResponses?.length > 0) {
      files.push(...filesResponses?.filter((file) => !file?.error));
    }

    const data = JSON.stringify({
      ...this.banner,
      '@class': 'br.com.stoom.avenida.model.AvenidaBanner',
      files: files,
    });

    const response = await BannersAPI.update(this.banner.uuid, data);
    this.loading = false;

    if (response?.error || response === undefined) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response?.error ?? "Ocorreu um erro inesperado");
    } else {
      newFiles.map(async (item) => {
        await this.removeFile(item);
      });
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Atualizado com sucesso!');
    }

    return response;
  }

  async get(uuid) {
    this.loading = true;
    const response = await BannersAPI.get(uuid);
    this.loading = false;
    this.banner = new BannerModel(response);
  }

  async getByFileUuid(uuid) {
    this.bannerEdit = this.banner.files.find((banner) => banner.uuid === uuid);
  }

  async updateBannerEdit(name, value) {
      switch (name) {
        case 'price':
        case 'price2':
          if (value) {
          const price = parseFloat(value.replace('R$ ', '').replace(',', '.'));
          this.bannerEdit[name] = price;
          return;
        } else {
          this.bannerEdit[name] = value;
          return;
        }
        case 'file':
          this.bannerEdit.file = value[0];
          return;
        default:
          this.bannerEdit[name] = value;
      }
  }

  async updateBannerFile(file) {
    const files = this.banner.files;
    const indexToRemove = files.findIndex((item) => item.uuid === file.uuid);
    files.splice(indexToRemove, 1);
    files.push(file);
    this.banner.files = files;
    this.bannerEdit = {};
    this.banner = new BannerModel(this.banner);
  }

  async delete(uuid) {
    this.loading = true;
    const response = await BannersAPI.delete(uuid);
    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Deletado com sucesso!');
    }

    return response;
  }

  async addFile() {
    const tempBanner = this.banner;
    tempBanner.files.push({
      uuid: Math.random() * 21,
      file: this.bannerFile.banner_file_file[0],
      description: this.bannerFile.description ?? 'Descrição',
      link: this.bannerFile.link,
      link2: this.bannerFile.link2,
      name: this.bannerFile.banner_file_name,
      name2: this.bannerFile.name2,
      price2: this.bannerFile.price2,
      ordering: this.bannerFile.banner_file_ordering,
      price: this.bannerFile.price,
      categoryBranch: this.bannerFile.categoryBranch
    });

    this.banner = new BannerModel(tempBanner);
    this.bannerFile = {};
  }

  async removeFile(file) {
    if (this.banner?.uuid && file?.uuid) {
      await BannersAPI.removeImage(this.banner.uuid, file.uuid);
      if (this.banner?.uuid) await this.get(this.banner?.uuid);
    } else {
      const tempBanner = this.banner;
      tempBanner.files = tempBanner.files.filter((item) => item !== file);
      this.banner = new BannerModel(tempBanner);
    }
  }

  onChangeBannerFile(prop, value) {
    switch (prop) {
      default: {
        this.bannerFile[prop] = value;
      }
    }
  }

  onChange(prop, value) {
    const tempBanner = this.banner;
    switch (prop) {
      case 'price':
      case 'price2':
        if (value) {
        const price = parseFloat(value.replace('R$ ', '').replace(',', '.'));
        this.onChangeBannerFile(prop, price);
        return;
      } else {
        this.onChangeBannerFile(prop, value);
        return;
      }
      case 'banner_file_file':
      case 'banner_file_name':
      case 'banner_file_ordering':
      case 'link':
      case 'link2':
      case 'description':
      case 'name2':
        this.onChangeBannerFile(prop, value);
        return;
      case 'branch':
        tempBanner.branch = new BranchModel({ uuid: value.value });
        break;
      case 'tag':
        tempBanner.tag = value.value;
        break;
      default:
        tempBanner[prop] = value;
    }

    this.banner = new BannerModel(tempBanner);
  }
}

export default BannersStore;
